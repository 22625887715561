import React from "react";
import "../SCSS/Footer.scss";
const Footer = () => {
	return (
		<div id="footer">
			<b>Created by Aaron McCready</b>
			<p>
				Logo by {""}
				<a
					href="https://www.linkedin.com/in/jimmy-tran007"
					target="_blank"
				>
					Jimmy Tran
				</a>
			</p>
		</div>
	);
};

export default Footer;