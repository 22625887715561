import "./SCSS/App.scss";
import Navbar from './component/Navbar.js';
import Home from './component/Home.js';
import Skills from './component/Skills.js';
import Contact from './component/Contact.js';
import Footer from './component/Footer.js';
import Projects from './component/Projects.js'

import {
  navbarData,
  homepageData,
  skillsData,
  projectsData
} from "./data/Data";

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//           Test
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

const App = () => {
	return (
		<div id="appContainer">
			<Navbar navbarData={navbarData}></Navbar>
				<main id="content">
					<Home homepageData={homepageData}></Home>
					<Skills skillsData={skillsData}></Skills>
					<Projects projectsData={projectsData}></Projects>
					<Contact homepageData={homepageData}></Contact> 
				</main>
			 <Footer></Footer>  
		</div>
	);
};

export default App;
