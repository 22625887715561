import htmlLogo from "../IconsAndImages/html.svg";
import cssLogo from "../IconsAndImages/css.svg";
import javascriptLogo from "../IconsAndImages/javascript.png";
import reactLogo from "../IconsAndImages/react.png";
import sassLogo from "../IconsAndImages/sass.png";
import npmLogo from "../IconsAndImages/npm.png";
import gitLogo from "../IconsAndImages/git.png";
import bootstrapLogo from "../IconsAndImages/bootstrap.svg";
import rubyLogo from "../IconsAndImages/ruby.svg";
import csharpLogo from "../IconsAndImages/csharp.svg";
import restaurantApp from "../IconsAndImages/restaurant_app.png"
import clothingApp from "../IconsAndImages/clothing_app.png"
import weatherApp from "../IconsAndImages/weather_app.png"
import storiesApp from "../IconsAndImages/stories_app.png"
import newstandApp from "../IconsAndImages/news_app.png"

export const navbarData = ["Home", "Skills", "Projects","Contact"];

export const skillsData = [
    {
		skillName: "HTML",
		skillImage: htmlLogo,
	},
	{
		skillName: "CSS",
		skillImage: cssLogo,
	},
	{
		skillName: "Javascript",
		skillImage: javascriptLogo,
	},
	{
		skillName: "React",
		skillImage: reactLogo,
	},
	{
		skillName: "Bootstrap",
		skillImage: bootstrapLogo
	},
	{
		skillName: "C#",
		skillImage: csharpLogo
	},
	{
		skillName: "Ruby",
		skillImage: rubyLogo
	},
    {
		skillName: "SASS/SCSS",
		skillImage: sassLogo,
	},
    {
		skillName: "NPM",
		skillImage: npmLogo,
	},
	{
		skillName: "Git",
		skillImage: gitLogo,
	}
];

export const projectsData = [
	{
		projectName: "Clothing Shop",
		projectImage: clothingApp,
		projectDescription: "A website to simulate a basic webstore. Complete with a functioning cart and checkout process. WARNING: No bank information is stored and transactions are disabled.",
		techStack: ["HTML", "React", "React Router", "CSS", "Firebase"],
		demoLink: "https://aaronmccready.github.io/clothingshop/",
		codeLink: "https://github.com/aaronmccready/clothingshop"
	},
	{
		projectName: "Weather App",
		projectImage: weatherApp,
		projectDescription: "A live weather app that fetches data from the OpenWeatherAPI. Complete with a search field to find any city, a geolocation button to get the weather data for your current location, and a feature to store your favourite locations.",
		techStack: ["HTML", "React", "CSS", "Styled Components", "OpenWeatherAPI"],
		demoLink: "https://aaronmccready.github.io/weather_app/",
		codeLink: "https://github.com/aaronmccready/weather_app"
	},
	{
		projectName: "Restaurant",
		projectImage: restaurantApp,
		projectDescription: "A basic front page for a local restaurant.",
		techStack: ["HTML", "React", "SCSS/SASS"],
		demoLink: "https://aaronmccready.github.io/restaurant/",
		codeLink: "https://github.com/aaronmccready/restaurant"
	},
	{
		projectName: "Stories App",
		projectImage: storiesApp,
		projectDescription: "A basic travel journal app. The user can enter their name and email which is then stored into the database.",
		techStack: ["HTML",  "CSS/SCSS", "Ruby", "Ruby on Rails"],
		demoLink: "https://aaron-m-stories.herokuapp.com/",
		codeLink: "https://github.com/aaronmccready/stories"
	},
	{
		projectName: "Newstand App",
		projectImage: newstandApp,
		projectDescription: "A site where authorized users can post, edit, and delete articles. Users can create an account by entering their name, an email, and a password. Once they have an account they can view the articles.",
		techStack: ["HTML",  "CSS/SCSS", "Ruby", "Ruby on Rails"],
		demoLink: "https://aaron-m-newstand.herokuapp.com/",
		codeLink: "https://github.com/aaronmccready/newstand"
	}
	
];

export const homepageData = {
    devName: "Aaron McCready",
    devTitle: "Web Developer",
    linkedIn: "https://www.linkedin.com/in/aaron-mccready/",
    github: "https://github.com/aaronmccready",
    resume: "",
};